import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './Qlik.sass';
import UserManagementJsx from './Qlik.jsx';
import axios from 'axios';


import { qlikLogin, getQCSHeaders, connectEnigma, handleDisconnect, getTheme,  APP_ID } from './qlikUtils';

function Qlik() {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [requests, setRequests] = useState([]);
  const [manufacturers, setManufacturers] = useState(["Sonyx","samsungx"]);
  const [isLoading, setIsLoading] = useState(false);
  const [bulkManufacturer, setBulkManufacturer] = useState('');

// =============  Testing =============================================================
  const [qlikSheet, setQlikSheet] = useState([]);


  const history = useHistory();
  // for filtering
  const [limit, setLimit] = useState(10);
  const [searchRequest, setSearchRequest] = useState({
    page: 1,
    search: '',
    manufacturer_name: '',

  });
  const maxLimit = 1;
  var searchTimeout = null;

  const [theme, setTheme] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    async function initialize() {
      try {
        const loggedIn = await qlikLogin();
        if (loggedIn) {
          setIsLoggedIn(true);
          const qcsHeaders = await getQCSHeaders();
          const [session, enigmaApp] = await connectEnigma(qcsHeaders, APP_ID);
          handleDisconnect(session);
          const theme = await getTheme(enigmaApp);
          setTheme(theme);
          // renderSingleIframe('qlik_frame', APPID, SHEETID, theme);
        }
      } catch (error) {
        console.error('Initialization error:', error);
      }
    }
  
    initialize();
    getQlikSheets()

  }, [searchRequest])



  const getQueryString = () => {
    let obj = {},
        str = [];
    if (searchRequest.search) { obj['search'] = searchRequest.search; }
    if (searchRequest.manufacturer_name) { obj['manufacturer_name'] = searchRequest.manufacturer_name; }
    if (searchRequest.page) { obj['page'] = searchRequest.page; } else { obj['page'] = 1; }
    if (limit && !isNaN(limit) && parseInt(limit, 10) <= 100 && parseInt(limit, 10) >= 1) { obj['limit'] = limit; } else { obj['limit'] = maxLimit; }

    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    // alert(str)
    return str.join("&");
  }



  const searchUser = (e) => {
    if (searchRequest.search !== e.target.value) {
      clearTimeout(searchTimeout);
      var tempSearch = e.target.value;
      searchTimeout = setTimeout(() => { setSearchRequest({
        'page': 1,
        'search': tempSearch
      }); }, 350);
    }
  }

  const setActivePage = (page) => {
    setSearchRequest({'page': page, 'search': searchRequest.search});
  }



  const handleManufacturer = (e) => {
    setBulkManufacturer(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
    console.log("e.target.value", e.target.value)
    if (searchRequest.search !== e.target.value) {
      clearTimeout(searchTimeout);
      var tempSearch = e.target.value;
      searchTimeout = setTimeout(() => { setSearchRequest({
        'page': 1,
        'manufacturer_name': tempSearch
      }); }, 350);
    }
  }

  

// ==============================================================Testing==============================================================
const getQlikSheets = () => {
  let key = localStorage.getItem('key');
  let timestamp = localStorage.getItem('timestamp');
  if (!key || !timestamp) {
    localStorage.clear();
    history.push(`/login`);
  }
  let currentTime = Date.now();
  let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
    if (timeLimit <= currentTime) {
      localStorage.clear();
      history.push(`/login`);
    }

  let config = {
    headers:{
    "Authorization": `Bearer ${key}`
    }
  }
  let queryString = getQueryString();
  console.log("queryString", queryString)
  setIsLoading(true)
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}/qlik/sheet?` + queryString, config)
    .then(res => {
      setManufacturers(res.data.manufacturers)
      setTotal(res.data.qlik_sheet_totals);
      // alert(res.data)
      console.log("***************************************",res.data.qlik_sheet_totals)
      // setTotal(res.data.data.total);
      // if(res.data.data.users) {
      //   let d = res.data.data.users;
      //   d.forEach((el, i) => {
      //     el.isChecked = false
      //   });
      //   setUsers(d)
      // }
    
      // setManufacturers(res.data.data.manufacturers)
      setIsLoading(false)

      console.log("Qlik Data ======= ", res.data)
      const serializedData = res.data.qliksheet_list; // Assuming the serialized data is under 'data' key
      const dataArray = Object.values(serializedData); // Convert object to array (if necessary)
      
      setQlikSheet(dataArray)
      // setManufacturers(res.data.manufacturers)
      
      
      console.log("manufacturers", manufacturers)


    }).catch(err => {
        // if(err.response.status) {
        //   history.push(`/dashboard`);
        // }
    })
}
const handleClick2 = (iframe_url) => {
  // Redirect to Page2 and pass state value
  history.push({
    pathname: '/dashboard/iframe',
    state: { data : iframe_url  }
  });
};

  return(
      <UserManagementJsx
      users={users}
      requests={requests}
      isLoading={isLoading}
      manufacturerList={manufacturers}
      searchUser={searchUser}
      maxLimit={maxLimit}
      total={total}
      page={searchRequest.page}
      limit={limit}
      setActivePage={setActivePage}
      qlikSheet={qlikSheet}
      handleClick2={handleClick2}
      />
  )
}

export default Qlik;
