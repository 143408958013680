// qlikUtils.js
// import {useHistory} from 'react-router-dom';
import enigma from 'enigma.js';

const TENANT = 'cmsdistribution.eu.qlikcloud.com';
const JWTENDPOINT = 'https://api.dev.mycmsdistribution.com/api/qlik/token';
const WEBINTEGRATIONID = 'QdQbOpoi3FNldPL_9hGiqORPjoaHA_sV';
const APPID = '6639b1b5-9bb0-4cec-a66c-5ec5e763ecd0';

export async  function qlikLogin() {
    const loggedIn = await checkLoggedIn();
    if (loggedIn.status !== 200) {
      const tokenRes = await (await getJWTToken(JWTENDPOINT)).json();
      const loginRes = await jwtLogin(tokenRes.body);
      if (loginRes.status !== 200) {
        alert('Something went wrong while logging in.');
        throw new Error('Login error');
      }
      const recheckLoggedIn = await checkLoggedIn();
      if (recheckLoggedIn.status !== 200) {
        alert('Third-party cookies are not enabled in your browser settings and/or browser mode.');
        throw new Error('Cookie error');
      }
    }
    return true;
  }
  
  async function checkLoggedIn() {
    return await fetch(`https://${TENANT}/api/v1/users/me`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        'qlik-web-integration-id': WEBINTEGRATIONID,
      },
    });
  }
  
  async function getJWTToken(jwtEndpoint) {
    // const history = useHistory();

    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      // history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        // history.push(`/login`);
      }
  
    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      },
      mode: 'cors',
      method: 'GET',
    }

    return await fetch(jwtEndpoint, config);
  }
  
  async function jwtLogin(token) {
    const authHeader = `Bearer ${token}`;
    return await fetch(`https://${TENANT}/login/jwt-session?qlik-web-integration-id=${WEBINTEGRATIONID}`, {
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      headers: {
        'Authorization': authHeader,
        'content-type': 'application/json',
        'qlik-web-integration-id': WEBINTEGRATIONID,
      },
    });
  }
  
  export async function getQCSHeaders() {
    const response = await fetch(`https://${TENANT}/api/v1/csrf-token`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        'qlik-web-integration-id': WEBINTEGRATIONID,
      },
    });
  
    const csrfToken = new Map(response.headers).get('qlik-csrf-token');
    return {
      'qlik-web-integration-id': WEBINTEGRATIONID,
      'qlik-csrf-token': csrfToken,
    };
  }
  
  export async function connectEnigma(qcsHeaders, appId) {
    const [session, app] = await getEnigmaSessionAndApp(qcsHeaders, appId);
    return [session, app];
  }
  
  async function getEnigmaSessionAndApp(headers, appId) {
    const params = Object.keys(headers)
      .map((key) => `${key}=${headers[key]}`)
      .join('&');
  
    const schema = await (await fetch('https://unpkg.com/enigma.js@2.7.0/schemas/12.612.0.json')).json();
  
    try {
      return await createEnigmaAppSession(schema, appId, params);
    } catch {
      await new Promise(resolve => setTimeout(resolve, 1500));
      return await createEnigmaAppSession(schema, appId, params);
    }
  }
  
  async function createEnigmaAppSession(schema, appId, params) {
    const session = enigma.create({
      schema,
      url: `wss://${TENANT}/app/${appId}?${params}`,
    });
    const enigmaGlobal = await session.open();
    const enigmaApp = await enigmaGlobal.openDoc(appId);
    return [session, enigmaApp];
  }
  
  export async function getTheme(enigmaApp) {
    const createAppProps = await enigmaApp.createSessionObject({
      qInfo: {
        qId: 'AppPropsList',
        qType: 'AppPropsList',
      },
      qAppObjectListDef: {
        qType: 'appprops',
        qData: {
          theme: '/theme',
        },
      },
    });
    const appProps = await enigmaApp.getObject('AppPropsList');
    const appPropsLayout = await appProps.getLayout();
    return appPropsLayout.qAppObjectList.qItems[0].qData.theme;
  }
  
  export function handleDisconnect(session) {
    session.on('closed', () => {
      console.log('Due to inactivity or loss of connection, this session has ended.');
    });
  
    session.on('suspended', () => {
      console.log('Due to loss of connection, this session has been suspended.');
    });
  
    window.addEventListener('offline', () => {
      session.close();
    });
  }
  
  function renderSingleIframe(frameId, appId, sheetId, theme) {
    const frameUrl = `https://${TENANT}/single/?appid=${appId}&sheet=${sheetId}&theme=${theme}&opt=ctxmenu,currsel`;
    document.getElementById(frameId).setAttribute('src', frameUrl);
  }

// You can also export APPID and other constants if they are needed across different files.
export const APP_ID = APPID;
