import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./IframeScreen.sass";
import IframeScreenJsx from "./IframeScreen.jsx";
import { useLocation } from 'react-router-dom';

import { qlikLogin, getQCSHeaders, connectEnigma, handleDisconnect, getTheme,  APP_ID } from '../qlikUtils';


function IframeScreen() {
  const location = useLocation()
  const data = location.state?.data

  const [qlikToken, setQlikToken] = useState(null);
  const history = useHistory();

const [theme, setTheme] = useState('');
const [isLoggedIn, setIsLoggedIn] = useState(false);

useEffect(() => {
  async function initialize() {
    try {
      const loggedIn = await qlikLogin();
      if (loggedIn) {
        setIsLoggedIn(true);
        const qcsHeaders = await getQCSHeaders();
        const [session, enigmaApp] = await connectEnigma(qcsHeaders, APP_ID);
        handleDisconnect(session);
        const theme = await getTheme(enigmaApp);
        setTheme(theme);
        // renderSingleIframe('qlik_frame', APPID, SHEETID, theme);
      }
    } catch (error) {
      console.error('Initialization error:', error);
    }
  }

  initialize();
}, []);



return (<IframeScreenJsx data={data}/>);
}

export default IframeScreen;
