import React from 'react';
import {Typography, InputLabel, Box, Container, Select, MenuItem, TextField, Button, CircularProgress} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export const IframeScreenJsx = (props) => {
  // const link = props.data
  const link = "https://www.cmsdistribution.com/"
  const link2 = props.data
  const link3 = "https://cmsdistribution.eu.qlikcloud.com/single/?appid=05e4203c-d575-4696-b2ee-40d547773bdd&sheet=014243ee-135e-460f-b0ee-d66275be503b&theme=horizon&opt=ctxmenu,currsel"
  const link4 = "https://programiz.pro"
return (
  // main-container dashboard
<div className='iframe-container iframe-screen'>
  {/* <p>Hi{props.data}Hi</p> */}
<iframe
                // ref={iframeRef}
                src={link2}
                title="CMS Iframe"
                frameborder="0" 
                scrolling="yes"  
         
                style={{ width: '100%', height: '100vh', border: 'none' ,  overflow: "auto"}}
                // style={{ width: '100%', height: '100%', border: 'none' }}
                
                              
                allowFullScreen
              ></iframe>
</div>
)
}

export default IframeScreenJsx;
